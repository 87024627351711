import styled from '@emotion/styled';
import rhythm from '../utils/rhythm';

const Styles = styled.main({
  '.stack': {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    flexWrap: 'wrap',

    svg: {
      height: `${rhythm(1)}`,
    },
  },

  '.line-break': {
    flexBasis: '100%',
    height: `${rhythm(1)}`,
  },
});

export default Styles;
