import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';

import * as Globals from '../../globals';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import ContentCol from '../components/contentCol/contentCol';
import ChatBanner from '../components/chatBanner/chatBanner';
import Styles from './about.styles';

import { ReactComponent as StackAngular } from '../images/stack/stack-angular.svg';
import { ReactComponent as StackFirebase } from '../images/stack/stack-firebase.svg';
import { ReactComponent as StackFlutter } from '../images/stack/stack-flutter.svg';
import { ReactComponent as StackGatsby } from '../images/stack/stack-gatsby.svg';
import { ReactComponent as StackGCloud } from '../images/stack/stack-gcloud.svg';
import { ReactComponent as StackIonic } from '../images/stack/stack-ionic.svg';
import { ReactComponent as StackNode } from '../images/stack/stack-node.svg';
import { ReactComponent as StackWordPress } from '../images/stack/stack-wordpress.svg';

const AboutPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo
        title="About Us"
        description="A complete platform to build, advise, and operate, we're a dedicated partner on your entrepreneurial journey."
      />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <ContentCol>
                <h1>The Talent and Experience to Help You Start Up</h1>
              </ContentCol>
            </Row>
          </Container>
        </BackgroundImage>

        <Container>
          <section className="my-5">
            <Row className="mb-5 justify-content-center text-center">
              <ContentCol>
                <p className="lead">
                  We've faced the countless challenges from fundraising &amp;
                  development to marketing &amp; operations, and we've built a
                  framework for navigating them.
                  <br />
                  <br />
                  With decades of experience in New York's thriving startup
                  community, our team offers a complete platform to build,
                  advise, and operate. We're a dedicated partner on your
                  entrepreneurial journey.
                </p>
              </ContentCol>
            </Row>

            <Row className="testimonial-row">
              <Col>
                <Img
                  fluid={data.arel.childImageSharp.fluid}
                  alt="Arel, Founder of Sprout Health"
                  className="rounded-circle"
                />
              </Col>
              <Col>
                <blockquote>
                  <p>
                    <span>&ldquo;</span>Generativ helped us re-imagine our
                    brands &amp; develop strategic digital assets for our
                    portfolio of companies. They've helped us launch products
                    &amp; companies from the ground up and been an integral part
                    of our marketing &amp; growth strategy for the past 3 years.
                    We trust the team at Generativ with our brand and our
                    reputation.
                  </p>
                  <footer>
                    Arel, Founder of{' '}
                    <a
                      href="https://www.sprouthealthgroup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sprout Health
                    </a>
                  </footer>
                </blockquote>
              </Col>
            </Row>
          </section>

          <hr />

          <section className="my-5">
            <Row className="mb-4 justify-content-center text-center">
              <ContentCol>
                <h2>The Tools in Our Stack</h2>
              </ContentCol>
            </Row>

            <div className="stack justify-content-center justify-content-md-around justify-content-xl-between">
              <StackAngular className="mr-3 mr-md-0" />
              <StackFirebase />
              <div className="d-flex d-md-none line-break" />
              <StackFlutter className="mr-3 mr-md-0" />
              <StackGatsby />
              <div className="d-flex d-xl-none line-break" />
              <StackGCloud className="mr-3 mr-md-0" />
              <StackIonic />
              <div className="d-flex d-md-none line-break" />
              <StackNode className="mr-3 mr-md-0" />
              <StackWordPress />
            </div>
          </section>

          <hr />

          <section className="my-5">
            <Row className="justify-content-center text-center mb-5">
              <ContentCol>
                <h2>The Talent Leading Our Team</h2>
              </ContentCol>
            </Row>

            <Row>
              <Col xs={12} lg={6} className="mb-lg-0 mb-4">
                <Row>
                  <Col xs={4}>
                    <Img
                      fluid={data.jon.childImageSharp.fluid}
                      alt="Jonathan Verbanets"
                      className="rounded-circle"
                    />
                  </Col>

                  <Col xs={8} className="pl-0">
                    <h3 className="mb-0">Jonathan Verbanets</h3>
                    <h4 className="text-dark">Co-Founder &amp; CTO</h4>
                    <p>
                      Jonathan is a highly skilled web and software developer
                      with significant management experience in designing,
                      developing, delivering and overseeing complex enterprise
                      software systems in eCommerce, healthcare and finance.
                      With a strong project management and SDLC background,
                      Jonathan has led global, cross-functional teams at Morgan
                      Stanley, eBay Classifieds and has garnered hands-on
                      leadership experience as a co-founder of multiple
                      startups.
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={4}>
                    <Img
                      fluid={data.josh.childImageSharp.fluid}
                      alt="Joshua Silberberg"
                      className="rounded-circle"
                    />
                  </Col>

                  <Col xs={8} className="pl-0">
                    <h3 className="mb-0">Joshua Silberberg</h3>
                    <h4 className="text-dark">Co-Founder &amp; CEO</h4>

                    <p>
                      A product lead with deep technical expertise, Joshua
                      brings a creative eye to his extensive portfolio of
                      front-end work helping companies build smarter products,
                      including a billion-dollar eCommerce exit. Skilled at
                      strategic operation, his leadership has contributed to
                      up-rounds for multiple companies and successful entree
                      into highly competitive verticals, including healthcare,
                      hospitality, real estate, and social.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        </Container>
      </Styles>
      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-about.jpg" }) {
      ...imageFluidExtraLarge
    }
    arel: file(relativePath: { eq: "founders/founder-arel.jpeg" }) {
      ...imageFluidSmallGrayscale
    }
    jon: file(relativePath: { eq: "headshots/headshot-jon.png" }) {
      ...imageFluidSmallGrayscale
    }
    josh: file(relativePath: { eq: "headshots/headshot-josh.png" }) {
      ...imageFluidSmallGrayscale
    }
  }
`;

export default AboutPage;
